import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Stamping",
  "description": "For bending metals into different shapes.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The stamping process is the process of bending metals into specific shapes. It’s made using cold metals as opposed to hot metals. Forging would be the method used in hot metals instead.
Usually, you won’t need to get parts stamped yourself, as the majority of the stamped metal parts in a product consist of fasteners and other readily available parts.
Stainless steel, along with aluminum, copper, nickel, and brass are suitable materials for stamping. Plastics are generally not suitable for stamping as they won’t bend but break.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      